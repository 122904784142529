import React from 'react';
import styled from 'styled-components';

export default function CreditCard(props) {
  const {onSubmit, disabled} = props;
  const [enableSumit, setEnableSubmit] = React.useState(false);

  React.useEffect(() => {
    setupCreditCard({
      cardNumberElem: document.getElementById('tappay-card-number'),
      expirationDateElem: document.getElementById('tappay-expiration-date'),
      ccvElem: document.getElementById('tappay-ccv'),
      setEnableSubmit,
    });
  }, []);

  return (
    <Wrapper>
      <form id="laya-checkout-form">
        <div className="field">
          <label>卡號</label>
          <div id="tappay-card-number"></div>
        </div>

        <div className="field">
          <label>卡片到期日</label>
          <div id="tappay-expiration-date"></div>
        </div>

        <div className="field">
          <label>卡片後三碼</label>
          <div id="tappay-ccv"></div>
        </div>

        <div className="action-bar">
          <button
            className="pay"
            disabled={!enableSumit || disabled}
            onClick={(evt) => getCreditCardPrime(evt, onSubmit)}>
            確認付款
          </button>
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & #laya-checkout-form {
    & > .field {
      display: flex;
      align-items: center;

      & > :first-child {
        width: 80px;
      }

      margin-bottom: 10px;
    }

    #tappay-card-number {
      border: 1px solid green;
      padding: 0 10px;
      height: 40px;
      width: 200px;
    }

    #tappay-expiration-date {
      border: 1px solid green;
      padding: 0 10px;
      height: 40px;
      width: 100px;
    }

    #tappay-ccv {
      border: 1px solid green;
      padding: 0 10px;
      height: 40px;
      width: 100px;
    }
  }
`;

function setupCreditCard({
  cardNumberElem,
  expirationDateElem,
  ccvElem,
  setEnableSubmit,
}) {
  window.TPDirect.card.setup({
    fields: {
      number: {
        element: cardNumberElem,
        placeholder: '**** **** **** ****',
      },
      expirationDate: {
        element: expirationDateElem,
        placeholder: 'MM / YY',
      },
      ccv: {
        element: ccvElem,
        placeholder: '後三碼',
      },
    },
    styles: {
      input: {
        'font-size': '16px',
      },
      ':focus': {
        color: 'black',
      },
      '.valid': {
        color: 'green',
      },
      '.invalid': {
        color: 'red',
      },
      '@media screen and (max-width: 400px)': {
        input: {
          color: 'orange',
        },
      },
    },
  });

  window.TPDirect.card.onUpdate(function (update) {
    console.log('TPDirect.card.onUpdate', update);
    setEnableSubmit(update.canGetPrime);
  });
}

function getCreditCardPrime(evt, onSubmit) {
  function forceBlurIos() {
    if (!isIos()) {
      return;
    }
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    // Insert to active element to ensure scroll lands somewhere relevant
    document.activeElement.prepend(input);
    input.focus();
    input.parentNode.removeChild(input);
  }

  function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  evt.preventDefault();

  // fix keyboard issue in iOS device
  forceBlurIos();

  const tappayStatus = window.TPDirect.card.getTappayFieldsStatus();
  console.log(tappayStatus);

  // Check TPDirect.card.getTappayFieldsStatus().canGetPrime before TPDirect.card.getPrime
  if (tappayStatus.canGetPrime === false) {
    alert('can not get prime');
    return;
  }

  // Get prime
  window.TPDirect.card.getPrime(function (result) {
    if (result.status !== 0) {
      alert('get prime error ' + result.msg);
      return;
    }

    onSubmit(result.card.prime);
  });
}
