import React from 'react';

const RATE = 2000; //ms

export default function useDebounce() {
  const busy = React.useRef(false);

  const debounce = async (callback) => {
    if (!busy.current) {
      busy.current = true;
      callback();

      setTimeout(() => {
        busy.current = false;
      }, RATE);
    }
  };

  return {debounce};
}
