import React from 'react';
import styled from 'styled-components';
import Payment from '../../Components/Payment';
import Config from '../../../data.json';
import qs from 'query-string';

export default function MobilePaymentPage(props) {
  const params = qs.parse(props.location.search);
  const amount = parseFloat(params.amount || '100');

  return (
    <Wrapper>
      <div className="logo">
        <img src="/images/logo.png" alt="laya" />
      </div>

      <div className="content">
        <Payment
          amount={amount}
          forceMethod={params.method}
          mobileCallback={({method, prime}) => {
            let deepLinkUrl;

            if (method === 'credit' || method === 'apple') {
              // this url is only used to show a loading UI during app handling deep link
              deepLinkUrl = `${Config.apiHost}/checkout/loading?method=${method}`;
            } else {
              deepLinkUrl = `${Config.mobileAppHost}://web-payment-callback?method=${method}`;
            }

            if (prime) {
              deepLinkUrl += `&prime=${prime}`;
            }

            window.location.href = deepLinkUrl;
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 249, 241);
  min-height: 100vh;

  & > .logo {
    width: 144px;
    height: 144px;
    margin-bottom: 20px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: translateY(-100px);
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);
    }
  }

  & > .content {
    padding: 10px;
    margin: 0 auto;
    width: 300px;
    transform: translateY(-100px);
  }
`;
