import React from 'react';
import styled from 'styled-components';
import data from '../../../data.json';
import {ApplePay as Icon} from '@styled-icons/fa-brands/ApplePay';

export default function ApplePay(props) {
  const {onSubmit, disabled, amount} = props;
  const [checking, setChecking] = React.useState(true);
  const [supported, setSupported] = React.useState(false);

  React.useEffect(() => {
    async function initApplePay() {
      setSupported(await setupApplePay(amount));
      setChecking(false);
    }

    initApplePay();
  }, [amount]);

  return (
    <Wrapper id="rev-ApplePay">
      <button
        className="rev-ApplePay-btn"
        disabled={checking || !supported || disabled}
        onClick={() => getApplePayPrime(onSubmit)}>
        <Icon
          size={48}
          color={checking || !supported || disabled ? '#aaa' : 'black'}
        />
      </button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & button.rev-ApplePay-btn {
    border: 1px solid black;
    outline: none;
    border-radius: 4px;
    background-color: white;
    padding: 0px 88px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

    &:disabled {
      background-color: #eee;
      border: 1px solid #aaa;
    }
  }
`;

async function setupApplePay(amount) {
  var paymentRequestData = {
    supportedNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
    supportedMethods: ['apple_pay'],
    displayItems: [
      {
        label: 'Laya Burger',
        amount: {
          currency: 'TWD',
          value: amount.toFixed(2),
        },
      },
    ],
    total: {
      label: 'Laya Burger',
      amount: {
        currency: 'TWD',
        value: amount.toFixed(2),
      },
    },
    // optional
    options: {
      requestPayerEmail: false,
      requestPayerName: false,
      requestPayerPhone: false,
      requestShipping: false,
    },
  };

  return new Promise((resolve) => {
    if (window.TPDirect.paymentRequestApi.checkAvailability()) {
      try {
        window.TPDirect.paymentRequestApi.setupApplePay({
          merchantIdentifier: data.merchantIdentifier,
          countryCode: 'TW',
        });

        window.TPDirect.paymentRequestApi.setupPaymentRequest(
          paymentRequestData,
          (result) => {
            console.log(
              'TPDirect.paymentRequestApi.setupPaymentRequest.result',
              result,
            );

            if (result.canMakePaymentWithActiveCard && window.ApplePaySession) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
        );

        // early return, let Tappay's callback decides whether we can support ApplePay
        return;
      } catch (ex) {
        console.warn('err in setupPaymentRequest', ex);
      }
    }

    resolve(false);
  });
}

function getApplePayPrime(callback) {
  window.TPDirect.paymentRequestApi.getPrime((result) => {
    console.log('paymentRequestApi.getPrime result', result);
    if (result.prime) {
      callback(result.prime);
    }
  });
}
