import React from 'react';
import styled from 'styled-components';
import {Radio} from 'antd';
import {Helmet} from 'react-helmet';
import CreditCard from './CreditCard';
import GooglePay from './GooglePay';
import ApplePay from './ApplePay';
import data from '../../../data.json';
import Theme from '../../Theme';
import {useSelectedStore} from '../SelectStoreModal';
import useDebounce from '../../Hooks/useDebounce';

const PaymentMethodDisplay = {
  cash: {
    label: '現場付款',
  },
  credit: {
    label: '信用卡',
  },
  google: {
    label: 'Google Pay',
  },
  apple: {
    label: 'Apple Pay',
  },
  line: {
    label: 'LINE Pay',
  },
  jkopay: {
    label: '街口支付',
  },
};
const PaymentMethods = Object.keys(PaymentMethodDisplay);

export default function Payment(props) {
  const {
    mobileCallback,
    webCallback,
    forceMethod,
    amount,
    disabled = false,
  } = props;
  const [isTapPayReady, setIsTapPayReady] = React.useState(false);
  const {debounce} = useDebounce();
  const [method, setMethod] = React.useState(forceMethod || PaymentMethods[0]);
  const selectedStore = useSelectedStore();

  const allowedMethods = React.useMemo(() => {
    let methods = PaymentMethods.filter((m) => {
      if (
        selectedStore &&
        selectedStore.payment_method.indexOf(m.toUpperCase()) > -1
      ) {
        return true;
      }
      return false;
    });

    return methods;
  }, [PaymentMethods, selectedStore]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (typeof window !== 'undefined') {
        if (window.TPDirect) {
          tappayInit();
          setIsTapPayReady(true);
          clearInterval(timer);
        }
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    let defaultPayment = null;
    if (selectedStore && selectedStore?.payment_method?.length > 0) {
      let firstPayment = selectedStore?.payment_method?.[0].toLowerCase();
      if (allowedMethods.includes(firstPayment)) {
        defaultPayment = firstPayment;
      }
    }
    if (forceMethod) {
      defaultPayment = forceMethod;
    }
    setMethod(defaultPayment);
  }, [selectedStore, forceMethod, allowedMethods]);

  function onSubmit(prime) {
    if (mobileCallback) {
      mobileCallback({method, prime});
      return;
    }

    if (webCallback) {
      webCallback({method, prime});
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <script src="https://pay.google.com/gp/p/js/pay.js"></script>
        <script src="https://js.tappaysdk.com/tpdirect/v5.13.0"></script>
      </Helmet>

      {isTapPayReady && !forceMethod && (
        <React.Fragment>
          <div
            style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
            <div style={{fontSize: 22}}>結帳方式</div>
            <div style={{margin: '0 8px'}}>|</div>
            <div>請選擇結帳方式</div>
          </div>

          <div className="payment-method-box">
            <div className="payment-method-list">
              {allowedMethods.map((m) => {
                return (
                  <div
                    key={m}
                    onClick={() => {
                      if (!disabled) {
                        setMethod(m);
                        setTimeout(() => {
                          window.scrollTo({top: 10000, behavior: 'smooth'});
                        }, 350);
                      }
                    }}>
                    <Radio checked={m === method} disabled={disabled}>
                      <div className="method-label">
                        {PaymentMethodDisplay[m].label}
                      </div>
                    </Radio>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}

      {isTapPayReady && (
        <div className="payment-form">
          {method === 'cash' && (
            <button
              className="pay"
              onClick={() => debounce(() => onSubmit(null))}
              disabled={disabled}>
              確認付款
            </button>
          )}
          {method === 'credit' && (
            <CreditCard
              onSubmit={(params) => debounce(() => onSubmit(params))}
              disabled={disabled}
              amount={amount}
            />
          )}
          {method === 'google' && (
            <GooglePay
              onSubmit={(params) => debounce(() => onSubmit(params))}
              disabled={disabled}
              amount={amount}
            />
          )}
          {method === 'apple' && (
            <ApplePay
              onSubmit={(params) => debounce(() => onSubmit(params))}
              disabled={disabled}
              amount={amount}
            />
          )}
          {method === 'line' && (
            <button
              className="linepay"
              onClick={() => debounce(() => onSubmit(null))}
              disabled={disabled}
              amount={amount}>
              <img src="/images/linepay.png" alt="linepay" />
            </button>
          )}
          {method === 'jkopay' && (
            <button
              className="jkopay"
              onClick={() => debounce(() => onSubmit(null))}
              disabled={disabled}
              amount={amount}>
              <img src="/images/jkopay.png" alt="jkopay" />
            </button>
          )}

          <div className="hint">*送出訂單後即無法取消</div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .payment-method-box {
    padding: 15px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    background-color: ${Theme.colors.bgYellow};
    margin-bottom: 60px;
  }

  & .payment-method-list {
    & > * {
      padding: 10px 0;
      border-bottom-width: 1px;
      border-bottom-color: #ccc;

      & .method-label {
        font-size: 1rem;
      }
    }
  }

  & .payment-form {
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    & > .hint {
      margin-top: 8px;
      font-size: 0.8rem;
      color: #aaa;
      text-align: center;
    }
  }

  & button.pay {
    width: 100%;
    display: block;
    padding: 10px 20px;
    background-color: ${Theme.colors.main};
    outline: none;
    border: none;
    border-radius: 30px;
    color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 200ms;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }

    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    &:disabled {
      background-color: #ccc;
    }
  }

  & button.linepay,
  button.jkopay {
    align-self: center;
    width: 240px;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    outline: none;
    border-radius: 4px;
    background-color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & img {
      width: 100px;
      object-fit: contain;
    }

    &:disabled {
      background-color: #eee;
      border: 1px solid #aaa;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

function tappayInit() {
  window.TPDirect.setupSDK(
    data.tappay_arg1,
    data.tappay_arg2,
    data.tappay_arg3,
  );
}
